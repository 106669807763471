<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
// import SidePanel from './components/Tutorial/Sidepanel.vue'
import Keycloak from 'keycloak-js'
// import Home from './views/Home.vue'
import { mapMutations } from 'vuex';
import axios from '@/axios'
export default {
  // components: {
  //   Home
  // },
   props: {
      keycloak: Keycloak,
      auth: Boolean
    },
  data() {
    return {
      initialX: '',
      initialY: '',
      active: false,
      dragItem: '',
      dragItem2: '',
      container: '',
      currentX: '',
      currentY: '',
      xOffset: 0,
      yOffset: 0,
      doubleClicked: false
    }
  },
  methods:{
       ...mapMutations({
      'changeIsRegister': 'SET_ISREGISTER'
    }),
    currentregistrasi() {
      axios
        .get(`/api/currentregistrasi`, {
          headers: {},
        })
        .then(({ data }) => {
           this.changeIsRegister(true)
        })
        .catch((err) => {
          this.changeIsRegister(false)
          this.$router.push({name: 'Register'}).catch(() => {})
          // console.log(err);
        });
    },
    checkSidepanel() {
        if (this.doubleClicked) {
          $('#tutorial').tooltip('hide')
            this.sidepanel = true
            setTimeout(()=> {
              document.getElementById('sidepanel-intro').style.right = '0px'
            }, 100)
        }
        this.doubleClicked = true;
        $('#tutorial').tooltip('show')
        setTimeout(() => {
            this.doubleClicked = false;
        }, 300);
      },
      hidePanel() {
        this.$store.dispatch('hideSidePanel')
      },
      dragStart(e) {
      if (e.type === "touchstart") {
        this.initialX = e.touches[0].clientX - this.xOffset;
        this.initialY = e.touches[0].clientY - this.yOffset;
      } else {
        this.initialX = e.clientX - this.xOffset;
        this.initialY = e.clientY - this.yOffset;
      }

      if ( e.target == this.dragItem2) {
        this.active = true;
      }
    },
    dragEnd(e) {
      this.initialX = this.currentX;
      this.initialY = this.currentY;

      this.active = false;
    },
    drag(e) {
      if (this.active) {
      
        e.preventDefault();
      
        if (e.type === "touchmove") {
          this.currentX = e.touches[0].clientX - this.initialX;
          this.currentY = e.touches[0].clientY - this.initialY;
        } else {
          this.currentX = e.clientX - this.initialX;
          this.currentY = e.clientY - this.initialY;
        }

        this.xOffset = this.currentX;
        this.yOffset = this.currentY;

        this.setTranslate(this.currentX, this.currentY, this.dragItem);
      }
    },
    setTranslate(xPos, yPos, el) {
      el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
      // el2.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";

    }
  },
  mounted(){
      if(!window.auth) {
        this.$router.push({name: 'Login'}).catch(() => {})
      } else {
        this.$router.push({name: 'home'}).catch(() => {})
        this.currentregistrasi()
    }
    
    // $('#tutorial').tooltip()
    // let token = localStorage.getItem('token')
    // if (token) {
    //   // this.$router.push('/referral')
    //   this.$store.commit("SET_LIST_TENANTS", token);
    // }else{
    //   this.$router.push('/loginPage')
    // }
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

  .carousel-indicators li {
    width: 12px;
    height: 12px;
    border-radius: 100%;
  }

</style>
